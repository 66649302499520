/*
* Custom
*/
@import "partials/reset";
@import "partials/animate";
@import "partials/bootstrap.min";
@import "partials/font-awesome";
@import "partials/_variables";
@import "partials/mmenun";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;400;600;700&family=Barlow:wght@400;600;700;800&display=swap');
@import "partials/styles";
