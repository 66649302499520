/* Media Screens */
html, body {
	width: 100%;
	margin: 0;
	padding: 0;
}
body {
	@include fz(18px, 1.44, #000, 400);
	font-family: $main_font;
	background: #fff;
	@include break(neutral) {
		
	}
	@include break(mobile) {
	}

	@include break(x-small) {
		font-size: 16px;
	}
	.dark-bg {
		color: #fff;
	}	
}
p {
	margin-bottom: 10px;	
}
.container {
	@include break(desktop) {
		width: calc(100% - 100px);
		max-width: 1273px;
	}
	@include break(medium) {
		width: calc(100% - 60px);
		max-width: none;
	}
	@include break(low-medium) {
		width: calc(100% - 40px);
		max-width: none;
	}
	@include break(neutral) {
		width: calc(100% - 15px);
	}
	@include break(mobile) {
		width: 100%;
	}
	&_large {
		max-width: 1540px;
	}
}

.city-wrapper {
	h6 {
		margin: 0;
		padding: 0;
		font-family: $main_font;
		color: #adb1c5;
		font-size: 18px;
		font-weight: 600;
		line-height: normal;
	}
	a {
		display: block;
		font-size: 24px;
		font-family: $second_font;
		font-weight: 600;
		color: #fff;
		line-height: normal;
		&:hover {
			color: #fff;
		}
		@include break(neutral) {
			font-size: 20px;
		}
	}
	.cities-wrapper & {
		& + .city-wrapper {
			margin-left: 90px;
			@include break(medium) {
				margin-left: 60px;
			}
			@include break(low-medium) {
				margin-left: 40px;
			}
			@include break(neutral) {
				margin-left: 20px;
			}
		}		
	}
}
.cities-mob-wrapper {
	margin-top: 54px;
	background-color: #1f265d;
	padding-bottom:3px;
	h6 {
		color: #adb1c5;
		text-align: center;
		font-size: 16px;
		text-transform: uppercase;
		margin: 0;
	}
}
/**     H E A D E R  **/

#my-header {
	background: #38499b url(../img/header-bg.jpg) no-repeat top center fixed;
	max-height: 623px;
	position: relative;
	z-index: 20;
	@include break(medium) {
		max-height: 583px;
	}
	@include break(low-medium) {
		max-height: 483px;
	}
	@include break(neutral) {
		max-height: 385px;
	}
	@include break(mobile) {
		max-height: none;
	}
	.sub-page & {
		max-height: 463px;
		@include break(medium) {
			max-height: 433px;
		}
		@include break(low-medium) {
			max-height: 363px;
		}
		@include break(neutral) {
			max-height: 293px;
		}
		@include break(mobile) {
			max-height: none;
		}
	}
}

.site-header {
	// position: relative;
	z-index:  100;
	transition: all .3s;
	position: relative;	
	@include break(medium) {
	}
	@include break(low-medium) {
		
	}
	@include break(neutral) {
		
	}
	@include break(mobile) {
		position: fixed;
		top: 0;
		left: 0;
		padding: 0;
		width: 100%;
		height: 54px;
		background: #38499b;		
		-webkit-transition: -webkit-transform .4s ease;
		transition: -webkit-transform .4s ease;
		transition: transform .4s ease;
		transition: transform .4s ease, -webkit-transform .4s ease;
		transition: transform .4s ease,-webkit-transform .4s ease;
		.mm-wrapper_opened & {
			z-index: 1;
		}
		
	}
	.row-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 18px 0 19px;
		.cities-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		a.btn {
			// background: #65af55;
			// &:hover {
			// 	background: #000;
			// }
			@include break(neutral) {
				font-size: 17px;
				padding: 6px 18px 12px;
			}
		}
	}
	#my-menu {
		@include break(mobile) {display: none;}
		padding: 0;
		z-index: 1000;
		text-align: center;
		transition: all .3s;
		border-top: 1px solid #8595d1;
		@include break(low-medium) {
		}
		@include break(neutral) {
		}
		.sf-menu {
			position: relative;
			font-size: 0;
			line-height: 0;
			white-space: nowrap;	
			text-align: center;	
			display: inline-block;

			& > li {
				margin: 0;
				padding: 0;			
				position: relative;
				display: inline-flex;
				flex-direction: row;
				align-items: center;
				font-size: 0;
				line-height: 0;
				& + li {
					margin-left: 28px;	
					@include break(custom) {
						margin-left: 23px;
					}
					@include break(medium) {
						margin-left: 12px;
					}
					@include break(low-medium) {
						margin-left: 9px;
					}
					@include break(neutral) {
						margin-left: 5px;
					}
				}
				& > a {
					padding: 16px 0 18px;
					border-top: 2px solid transparent;
					color: #93ffce;
					text-decoration: none;
					font-family: $main_font;
					font-weight: 600;
					line-height: normal;
					font-size: 18px;
					line-height: normal;
					transition: all .3s;
					text-transform: uppercase;
					display: inline-block;
					position: relative;
					letter-spacing: 0;
					margin-top: -1px;
					&:before {
						// content: '';
						position: absolute;
						left: 50%;
						bottom: 0;
						height: 2px;
						width: 0;
						background: #62c44f;
						transition: all .1s;
					}
					@include break(medium) {
					}
					@include break(low-medium) {
						font-size: 15px;
					}
					@include break(neutral) {
						font-size: 12px;
					}
				}

				&.sfHover,
				&:hover {
					& > a {
						color: #fff;
						border-top-color: #fff;
						&:before {
							
						}
					}
				}
				& > a.current-menu-item {
					color: #fff;
					border-top-color: #fff;
					
				}

				& > a.sf-with-ul {
				// margin-right: 10px;
				position: relative;
				&::after {
					// content:"\f0d7";
					display: block;
					font-family: "FontAwesome";
					font-size: 14px;
					color: #a08569;
					position: absolute;
					right: -13px;
					bottom: 7px;
				}
				@include break(neutral) {
					&:after {
						font-size: 12px;
						right: -10px;
						bottom: 6px;
					}
				}
			}	
		}
		.dd_menu {
			position: absolute;
			background: #ebecf5;
			min-width: 225px;
			display: none;
			text-align: left;
			padding: 0 7px; 
			top: 100%;
			left: 0;
			@include break(low-medium) {
			}
			@include break(neutral) {
				min-width: 195px;
			}			
			li {
				display: block;				
				position: relative;
				padding: 0;
				& + li {
					border-top: 1px solid #fff;
				}

				a {
					@include fz(17px, normal, #38499b, 600);
					font-family: $main_font;							
					text-decoration: none;
					border-left: none;
					padding: 13px 6px;
					display: block;
					letter-spacing: 0.57px;
					transition: all .3s;
					white-space: normal;
					&:hover {
						color: #4761e0;
					}
					@include break(low-medium) {
						font-size: 15px;
					}
					@include break(neutral) {
						font-size: 13px;
					}
				}
			}
		}
	}
	&.stuck {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		background-color: #38499b;
	}

}

	

	.logo {
		transition: all .3s;
		@include break(medium) {
		}
		@include break(low-medium) {
			max-width: 240px;
			height: auto;
		}
		@include break(neutral) {
			max-width: 170px;
		}
		@include break(mobile) {
			max-width: 166px;
			margin: 0;
			position: absolute;
			left: 11px;
			top: 10px;
		}
		a {
			display: block;
			position: relative;
			transition: all .3s;			
			img {
			
			}
		}
	}	
}

.mobile-menu-button {
	border: none;	
	padding: 0;
	width: 30px;
	height: 21px;
	position: fixed;
	right: 22px;
	top: 19px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	z-index: 10;

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: #93ffce;
		border-radius: 1.5px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
	span:nth-child(1) {
		top: 0;
		left: auto;
		right: 0;
	}

	span:nth-child(2), span:nth-child(3) {
		top: 9px;
		left: auto;
		right: 0;
	}

	span:nth-child(4) {
		top: 18px;
	}

	.mm-opening & span:nth-child(1) {
		top: 10px;
		width: 0%;
		left: 20%;
	}

	.mm-opening & span:nth-child(2) {
		transform: rotate(45deg);
		width: 100%;
	}

	.mm-opening & span:nth-child(3) {
		transform: rotate(-45deg);
		width: 100%;
	}

	.mm-opening & span:nth-child(4) {
		top: 10px;
		width: 0%;
		left: 50%;
	}	
	&:focus {
		outline: none;
	}	
}

/**    B R E A D C R U M B S      B R E A D C R U M B S         **/

.breadcrumbs {
	margin-bottom: -30px;
	padding-top: 30px;
	@include break(mobile) {
	}
	ul {
		margin: 0;
		padding: 0;
		li {
			float: left;
			@include fz(14px, 1.2, #9b9b9b, 400);						
			padding-right: 10px;
			span {
				font-family: "FontAwesome";
				color: #d8d8d8;				
			}
			a {
				text-decoration: none;
				color: #9b9b9b;
				&:hover {
					text-decoration: underline;
					color: #000;
				}
			}
		}
	}
}

/**    S L I D E R         **/

#main-slider, #slider-content {
	position: relative;
	background: #38499b;
	box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.3);
	border: 10px solid #fff;
	@include break(medium) {
		max-width: 1110px;
		margin-left: auto;
		margin-right: auto;
	}
	@include break(low-medium) {
		max-width: 922px;
	}
	@include break(neutral) {
		max-width: 723px;
	}		
	@include break(mobile) {
		max-width: none;
		border: none;
		margin: 0 -15px;
	}
	.carousel-inner {
		position: relative;
		max-height: 1100px;
		overflow: visible;
		.carousel-item {
			position: relative;	
			img {
				max-height: 100vh;
				object-fit: cover;
				@include break(extra-large) {
				}
				@include break(x-small) {
					
				}
			}
		}
	}
	.caption-container {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 50%;
		z-index: 5;
		padding: 0 59px;
		@include break(medium) {
		}
		@include break(low-medium) {
			padding: 0 40px;
		}
		@include break(neutral) {
			padding: 0 20px;
		}
		@include break(mobile) {
			padding: 10px 5px 10px 19px;
			top: calc(50% - 66px);
			// position: relative;
			// width: 100%;
			// transform: none;
			// left: 0;
			// bottom: 0;
		}
		@include break(x-small) {
		}
		h2 {
			font-size: 40px;
			line-height: normal;
			font-family: $second_font;
			font-weight: 600;
			color: #fff;
			margin: 0 0 18px;
			@include break(custom) {
				
			}
			@include break(medium) {
				font-size: 38px;
			}
			@include break(low-medium) {
				font-size: 34px;
			}
			@include break(neutral) {
				font-size: 27px;
				margin-bottom: 10px;
			}
			@include break(mobile) {
				font-size: 20px;
			}
			@include break(x-small) {
				
			}
			
		}
		h4 {
			font-size: 22px;
			font-family: $second_font;
			color: #fff;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 34px;
			@include break(neutral) {
				font-size: 18px;
				margin-bottom: 20px;
			}
			@include break(mobile) {
				font-size: 16px;
			}
			@include break(x-small) {
				display: none;
			}
		}
		a.btn {
			@include break(neutral) {
				font-size: 18px;
			}
			@include break(mobile) {
				display: none;
			}
		}		
	}
	.mobile-caption {
		padding: 44px 15px 43px;
		a.btn {
			display: inline-block;
			width: auto;
		}
	}
	.carousel-control-prev, .carousel-control-next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		// background: rgba(0,0,0,.4);
		text-align: center;
		text-decoration: none;
		bottom: auto;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		transition: all .3s;
		background: #fff;		
		z-index: 5;
		opacity: 1;
		border-radius: 50%;
		&:before {
			font-family: FontAwesome;
			color: #38499b;
			font-size: 35px;
			line-height: 40px;
			display: block;
		}
		&:hover {
		}
		@include break(neutral) {
			
		}
		@include break(mobile) {
			// top: auto;
			// transform: none;
			// bottom: 112px;
			
		}
		@include break(x-small) {
		}		
	}
	.carousel-control-prev {
		left: -25px;
		&:before {
			content: '\f104';			
		}		
		@include break(neutral) {
		}
		@include break(mobile) {
			left: 5px;
		}
	}
	.carousel-control-next {
		right: -25px;
		&:before {
			content: '\f105';			
		}
		
		@include break(neutral) {
		}
		@include break(mobile) {
			right: 5px;
		}
	}

	.carousel-indicators {
		bottom: -43px;
		@include break(medium) {
		}
		@include break(low-medium) {
		}
		@include break(neutral) {
		}
		@include break(mobile) {
			display: none;
		}
		li {
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: none;
			margin: 0;
			position: relative;
			opacity: 1;
			transition: all .3s;
			background: transparent;
			&:before {
				transition: all .3s;
				background-color: #adb1c5;
				content: '';
				width: 10px;
				height: 10px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			&.active, &:hover {
				background: #dce0f0;
				&:before {			
					background: #51ae6e;
				}
			}
			& + li {
				margin-left: 4px;
			}
		}
	}
}
#slider-content {
	border: none;
	box-shadow: none;
	background-color: #d8d8d8;
	@include break(neutral) {
		width: calc(100% - 5px);
	}
	@include break(mobile) {
		width: auto;
	}
	.caption-container {
		left: auto;
		right: 0;
		width: auto;
		transform: none;
		top: auto;
		bottom: 63px;
		padding: 35px 50px 39px;
		background-color: #38499b;
		@include break(low-medium) {
			padding: 25px 40px 29px;
			bottom: 50px;
		}
		@include break(neutral) {
			padding: 15px 24px 19px;
			bottom: 40px;
		}
		@include break(mobile) {
			position: relative;
			bottom: auto;
			padding: 35px 15px 39px;
		}
		h2 {
			padding: 0;
			margin: 0;
			font-size: 36px;
			line-height: 1;
			font-weight: 700;
			text-transform: uppercase;
			@include break(low-medium) {
				font-size: 30px;
			}
			@include break(neutral) {
				font-size: 24px;
			}
			@include break(mobile) {
				font-size: 36px;
				text-align: center;
			}
		}
	}
	.carousel-indicators {
		@include break(mobile) {
			display: flex;
			bottom: -28px;
		}
	}
	.carousel-control-prev, .carousel-control-next {
		@include break(mobile) {
			bottom: 90px;
			top: auto;
			transform: none;
		}
	}
	.carousel-control-prev {		
		@include break(mobile) {
			left: 50%;
			margin-left: -43px;
		}
	}
	.carousel-control-next {		
		@include break(mobile) {
			right: 50%;
			margin-right: -43px;
		}
	}
}

// S U B     P A G E    T O P   B L O C K
.top-block {
	background-color: #38499b;
	box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.3);
	border: 10px solid #fff;		
	@include break(medium) {
		max-width: 1110px;
		margin-left: auto;
		margin-right: auto;
	}
	@include break(low-medium) {
		max-width: 922px;
	}
	@include break(neutral) {
		max-width: 723px;
	}		
	@include break(mobile) {
		max-width: none;
		border: none;
		margin: 0 -15px;
	}
}

/**     C O N T E N T          **/
h1 {
	@include fz(50px, normal, #38499b, 800);	
	font-family: $main_font;
	letter-spacing: 5px;
	margin: 0 0 35px;
	padding: 0;
	@include break(custom) {
	}
	@include break(medium) {
	}
	@include break(low-medium) {
		font-size: 44px;
	}
	@include break(neutral) {
		font-size: 36px;
		letter-spacing: 3.8px;
		margin-bottom: 25px;
	}
	@include break(mobile) {
		font-size: 28px;
		letter-spacing: 2.8px;
		margin-bottom: 15px;
	}
	@include break(x-small) {
	}
	.dark-bg & {	
		color: #fff;	
	}
	.sub-page & {
	}
}
h2 {
	@include fz(30px, 1, #38499b, 700);	
	font-family: $main_font;
	margin: 0 0 20px;
	padding: 0;
	@include break(low-medium) {
	}
	@include break(neutral) {
		font-size: 24px
	}
	@include break(mobile) {
		font-size: 26px;
	}
	@include break(x-small) {
	}
	.dark-bg & {
		color: #fff;
	}
}
h3 {
	@include fz(26px, 1.15, #38499b, 400);	
	font-family: $main_font;
	margin: 0 0 15px;
	padding: 0;
	@include break(low-medium) {
	}
	@include break(neutral) {
		font-size: 22px;
	}
	@include break(mobile) {
		font-size: 24px;
	}
	.dark-bg & {
		color: #fff;
	}
}	
h4 {
	@include fz(24px, normal, #38499b, 600);	
	font-family: $second_font;
	margin: 0 0 13px;
	padding: 0;
	@include break(low-medium) {
	}
	@include break(neutral) {
	}
	.dark-bg & {
		color: #fff;
	}
}
h5 {
	@include fz(20px, normal, #38499b, 600);	
	font-family: $second_font;
	margin: 0 0 15px;
	padding: 0;
	@include break(neutral) {
	}
	@include break(mobile) {
	}
	.dark-bg & {
		color: #fff;
	}
}	
h6 {
	@include fz(18px, normal, #38499b, 600);	
	font-family: $main_font;
	margin: 0 0 15px;
	padding: 0;
	@include break(neutral) {
	}
	@include break(x-small) {
	}
	.dark-bg & {
		color: #fff;
	}
}
.owl-nav {
	div {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: 0;
		&:before {
			font-family: FontAwesome;
			color: #0b3869;
			opacity: 0.2;
			display: block;	
			font-size: 22px;
			transition: all .3s;		
		}
		&.owl-prev {
			left: -40px;
			@include break(low-medium) {
				left: 0;
			}
			&:before {
				content: '\f053';
			}
		}
		&.owl-next {
			right: -40px;
			@include break(low-medium) {
				right: 0;
			}
			&:before {
				content: '\f054';
			}
		}
		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}
}
section {
	position: relative;
	&.after-slider {
		padding: 130px 0 75px;
		background-color: #f8f9fe;
		@include break(low-medium) {
			padding-top: 120px;
			padding-bottom: 50px;
		}
		@include break(neutral) {
			padding-bottom: 30px;
		}
		@include break(mobile) {
			padding: 26px 0 24px;
		}
		@include break(x-small) {
			padding-bottom: 0;
		}
		h1 {
			text-align: center;
			margin-bottom: 55px;
			@include break(low-medium) {
				margin-bottom: 35px;
			}
			@include break(mobile) {
				margin-bottom: 10px;
			}
		}
		h2 {
			font-size: 24px;
			font-weight: 600;
			color: #38499b;
			font-family: $main_font;
			text-align: center;
			margin: 0 0 15px;
			@include break(mobile) {
				font-size: 20px;
				margin-bottom: 10px;
			}
		}
		a.more {
			margin-top: 30px;
			@include break(mobile) {
				margin-bottom: 6px;
			}
		}
	}
	&.manufacturing {
		padding: 69px 0 115px;
		@include break(low-medium) {
			padding: 50px 0 95px;
		}
		@include break(neutral) {
			padding: 30px 0 80px;
		}
		@include break(mobile) {
			padding: 60px 0 70px;
		}
		h2 {
			margin-bottom: 29px;
			@include break(mobile) {
				margin-bottom: 20px;
			}
		}
	}
	&.main-content {
		.home & {
			padding: 95px 0 75px;
			@include break(low-medium) {
				padding: 70px 0 50px;
			}
			@include break(neutral) {
				padding-top: 40px;
			}
			@include break(mobile) {
				padding-bottom: 30px;
				a.more {
					margin-bottom: 20px;
				}
			}
		}
		.sub-page & {
			padding: 106px 0 90px;
			@include break(low-medium) {
				padding-bottom: 50px;
			}
			@include break(neutral) {
				padding-top: 90px;
			}
			@include break(mobile) {
				padding: 40px 0 30px;
			}
			@include break(x-small) {
				padding-top: 30px;
			}
			h1 {
				text-align: center;
				text-transform: uppercase;
			}
		}
		.contact-page & {
			h1 {
				text-align: left;
			}
			.contact-block {
				margin-bottom: 10px;
				h4 {
					font-family: $main_font;
					margin-bottom: 10px;
				}
				a {
					color: #000;
					&.email {
						color: #4761e0;
					}
				}
			}
		}
		.machine-shop-page & {
			h1 {
				margin-bottom: 15px;
			}
			h3 {
				margin-bottom: 35px;
			}
		}
	}	
	&.parallax {
		background: #38499b url(../img/parallax-bg.jpg) no-repeat top center fixed;
		padding: 120px 0 140px;
		@include break(low-medium) {
			padding: 80px 0 90px;
		}
		@include break(neutral) {
			padding: 50px 0 60px;
		}
		@include break(mobile) {
			background-attachment: inherit;
		}
		.text-wrapper {
			border: 10px solid #fff;
			padding: 38px 33px 48px 37px;
			@include break(low-medium) {
				padding: 23px 18px 32px 17px;
			}
			@include break(mobile) {
				padding: 12px 10px 23px 24px;
				margin-bottom: 19px;
			}
			h2 {
				font-size: 40px;
				font-weight: 600;
				line-height: normal;
				font-family: $second_font;
				margin-bottom: 15px;
				@include break(not-mobile) {
					max-width: 507px;
				}
				@include break(medium) {
					font-size: 38px;
				}
				@include break(medium) {
					font-size: 34px;
				}
				@include break(mobile) {
					font-size: 30px;
				}
			}
			h5 {
				margin-bottom: 22px;
				font-size: 22px;
				font-weight: 400;
			}
		}
		.city-wrapper {
			@include break(mobile) {
				text-align: center;
			}
			h6 {
				font-size: 24px;
			}
			a {
				font-size: 36px;
			}
			& + .city-wrapper {
				margin-top: 35px;
			}
		}
	}
	
}
.block-parts {
	h1 {
		margin-bottom: 21px;
		text-align: left !important;
	}
	.part-wrapper {
		border: 1px solid #c6c6c6;
		padding: 38px 35px 30px;		
		margin-bottom: 20px;
		@include break(x-small) {
			padding: 15px 15px 20px;
		}
	}
	.item-part {
		display: flex;
		align-items: flex-start;
		padding-bottom: 20px;
		@include break(mobile) {
			display: block;
		}
		.item-body {
			margin-left: 60px;
			width: 100%;
			@include break(mobile) {
				margin-left: 0;
				margin-top: 30px;
			}
			a.more {
				color: #38499b;
				margin-top: 25px;
				&:hover {
					color: #fff;
				}
			}
		}
		& + .item-part {
			margin-top: 30px;
		}
		&.no-image {
			@include break(not-mobile) {
				margin-left: 324px;				
			}
		}
	}
}
figure {
	position: relative;
	img {
		width: 100%;
	}
	.caption {
		position: absolute;
		top: 38px;
		right: 0;
		padding: 18px 25px 20px;
		background-color: #38499b;
		font-size: 36px;
		color: #fff;
		font-weight: 100;
		line-height: 1;
		font-family: $second_font;
		text-transform: uppercase;
		@include break(large) {
			min-width: 350px;
		}
		@include break(medium) {
			font-size: 34px;
		}
		@include break(low-medium) {
			font-size: 28px;
			padding: 15px 20px 17px;
		}
		@include break(neutral) {
			font-size: 22px;
			padding: 10px 15px 12px;
			top: 20px;
		}
		@include break(x-small) {
			position: relative;
			top: auto;
			padding: 16px 15px 17px;
			text-align: center;
			font-size: 27px;
		}
		strong {
			font-weight: 700;
		}
	}
}
img.bordered {
	border: 10px solid #e6e6e6;
	@include break(neutral) {
		border-width: 6px;
	}
}
.item-service {
	border-bottom: 4px solid #adb1c5;
	margin-bottom: 25px;
	background: #fff;
	padding: 30px 34px 25px;
	transition: all .3s;
	box-shadow: 0 6px 10px -5px rgba(0, 0, 0, 0.1);
	@include break(low-medium) {
		padding: 20px 24px 15px;
	}
	@include break(mobile) {
		margin-bottom: 15px;
	}
	@include break(x-small) {
		margin: 0 -15px;
	}
	.title {
		font-size: 24px;
		color: #38499b;
		font-family: $second_font;
		font-weight: 600;
		line-height: normal;
		margin: 0 0 13px;
	}
	&:hover {
		border-bottom-color: #51ae6e;
	}
}

/* S I D E B A R   */
.sidebar {	padding-right: 20px;}


.social-list {
	li {
		display: inline-block;
		& + li {
			margin-left: 14px;
		}
		a {
			display: block;
			width: 46px;
			height: 46px;
			border-radius: 50%;
			text-align: center;
			text-decoration: none;
			transition: all .3s;
			box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
  			border: solid 1px rgb(71, 71, 71);
			&:before {
				font-family: 'simple-line-icons';
				font-size: 24px;
				line-height: 44px;
				color: #fff;
				display: block;
				transition: all .3s;
			}
			&.facebook {
				&:before {
					content: '\e00b';
				}
			}
			&.instagram {
				&:before {
					content: '\e609';
				}
			}
			&:hover {
				background: #ffe000;
				&:before {
					color: #000000;
				}
			}
		}
	}
}




/**     F O O T E R        F O O T E R         F O O T E R         **/
footer.site-footer {
	background: #f8f9fe;
	position: relative;
	border-top: 2px solid #f2f2f3;
	@include break(mobile) {
		
	}
	.footer-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 85px 0 100px;
		@include break(neutral) {
			flex-direction: column;
			padding: 35px 0 50px;
		}
	}
	.cities-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include break(x-small) {
			flex-direction: column;
			text-align: center;
		}
		.city-wrapper {
			h6 {
				color: #38499b;
			}
			a {
				color: #4761e0;
				font-size: 30px;
			}
			& + .city-wrapper {
				margin-left: 65px;
				@include break(low-medium) {
					margin-left: 45px;
				}
				@include break(x-small) {
					margin: 19px 0 0;
				}
			}
		}
	}
	.footer-logo {
		max-width: 410px;
		height: auto;
		@include break(low-medium) {
			max-width: 330px;
		}
		@include break(neutral) {
			margin-bottom: 40px;
		}
		@include break(x-small) {
			max-width: 225px;
		}
	}
}
.copyright-row {
	background: #1f265d;
	padding: 34px 0 36px;
	p {
		color: #adb1c5;
		font-size: 14px;
		margin-bottom: 0;	
		@include break(x-small) {
			max-width: 243px;
		}	
	}
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include break(neutral) {
			flex-direction: column;
		}
		@include break(x-small) {
			align-items: flex-start;
		}
	}
	.fnh-wrapper {	
		padding-right: 80px;
		position: relative;
		@include break(neutral) {
			margin-top: 50px;
		}
		@include break(x-small) {
			padding-right: 53px;
			margin-top: 23px;
		}
		.logo-link {
			position: absolute;
			bottom: -8px;
			right: 12px;
			@include break(x-small) {
				right: -6px;
			}
		}
		div {
			color: #adb1c5;
			font-size: 13px;
			a {
				color: #adb1c5;
				font-weight: 700;
			}
		}
	}
}

.mobile-bottom-button {
	position: fixed;
	left: 0;
	bottom: -50px;
	text-align: center;
	width: 100%;
	color: #fff;
	font-size: 16px;
	font-family: $main_font;
	font-weight:700;
	-webkit-transition: -webkit-transform .4s ease;
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	transition: transform .4s ease,-webkit-transform .4s ease;
	line-height: normal;
	z-index: 200;
	display: flex;
	align-items: center;
	div {
		width: 50%;
		background: #ffbc00;
		height: 50px;
		color: #000;
		line-height: 50px;
		text-transform: none;
		position: relative;
		& + div {
			border-left: 1px solid #000;
		}
	}
	span {
		&:before {
			content: '\f098';
			font-family: FontAwesome;
			font-size: 16px;
			font-weight: 400;
			display: inline-block;
			padding-right: 10px;
		}
	}
	a {
		font-weight: 900;
		color: #000;
		width: 100%;
		font-size: 0;
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		height: 100%;
	}
	&.show-button {
		bottom: 0;
	}
}
table {
	width: 100%;
	tr {
		& + tr {
			border-top: 1px dashed #d2d2d2;
		}
		td {
			padding: 5px 5px 5px 0;
			width: 50%;
			font-size: 20px;
			line-height: 1.44;
			@include break(low-medium) {
				font-size: 18px;
				width: 60%;
			}
			@include break(neutral) {
				font-size: 20px;
				width: 50%;
			}
			@include break(x-small) {
				font-size: 18px;
			}
			@include break(very-small) {
				font-size: 16px;
			}
			span {
				display: block;
			}
			& + td {
				text-align: right;
				@include break(low-medium) {
					width: 40%;
				}
				@include break(neutral) {
					width: 50%;
				}
			}
		}
	}
	&.prices {
		tr {
			border-bottom: 1px solid #d3c5b1;
		}
	}
}

/**     F O R M    F O R M    F O R M    F O R M    F O R M    F O R M     **/
.form-wrapper {
	padding: 37px 46px 58px;
	background: #38499b;
	@include break(low-medium) {
	}
	@include break(mobile) {
	}
	@include break(x-small) {
		padding: 25px 20px 35px;
	}
	.form-title {
		line-height: 1.2;
		color: #fff;
	}
	.description {		
		font-size: 26px;
		font-family: $main_font;
		color: #fff;
		line-height: 1.15;
		margin-bottom: 20px;
		@include break(low-medium) {
			font-size: 22px;
		}
	}

}
.form-wrapper {
	text-align: left;
	label {
		font-size: 18px;
		margin: 0;
		padding: 0;
		color: #fff;
		font-weight: 700;
		line-height: 1.44;
		display: block;
		padding-bottom: 5px;
		span {
			color: #93ffce;
		}
		@include break(x-small) {
			font-size: 16px;
		}
	}
}
.form-wrapper textarea,
.form-wrapper select,
.form-wrapper input {
	padding: 12px 10px 14px;
	width: 100%;
	@include fz(18px, normal, #38499b, 600);
	margin: 0 0 17px;
	border-radius: 2px;
	border: solid 1px #e2e5f0;
	background-color: #adb1c5;
	transition: all .3s;
	@include break(x-small) {
		font-size: 16px;
		padding: 10px 10px 12px;
	}
}
.form-wrapper select, .form-wrapper input[type="date"] {
	height: 34px;
}

.form-wrapper textarea:active,
.form-wrapper input:active,
.form-wrapper select:active,
.form-wrapper select:focus,
.form-wrapper textarea:focus,
.form-wrapper input:focus {
	border-color: #e2e5f0;
	background-color: #e7ebfb;
	outline: none;
}
.form-wrapper textarea {	
	height: 156px; max-width: 100%; 
	@include break(neutral) {
	}
	@include break(mobile) {
	}
}
.form-wrapper *::-webkit-input-placeholder {color:#463b2f; font-family: $main_font; font-size: 14px;}
.form-wrapper *::-moz-placeholder          {color:#463b2f; font-family: $main_font; font-size: 14px;}/* Firefox 19+ */
.form-wrapper *:-moz-placeholder           {color:#463b2f; font-family: $main_font; font-size: 14px;}/* Firefox 18- */
.form-wrapper *:-ms-input-placeholder      {color:#463b2f; font-family: $main_font; font-size: 14px;}

.form-wrapper *:focus::-webkit-input-placeholder {color:#000; font-family: $main_font; font-size: 14px;}
.form-wrapper *:focus::-moz-placeholder          {color:#000; font-family: $main_font; font-size: 14px;}/* Firefox 19+ */
.form-wrapper *:focus:-moz-placeholder           {color:#000; font-family: $main_font; font-size: 14px;}/* Firefox 18- */
.form-wrapper *:focus:-ms-input-placeholder      {color:#000; font-family: $main_font; font-size: 14px;}


#button_submit,
#fields .submit {
	border: none;
	border-radius: 22.5px;
	color: #fff;
	max-width: 186px;
	margin: 14px auto 0;
	display: block;
	&:hover, &:active {
		background: #000;
		color: #fff;
		box-shadow: none;
	}
	&:focus {
		top: 3px;
		color: #fff;
		box-shadow: none;
	}
	@include break(mobile) {
		margin-top: 5px;
	}
	// @include link_trans();
	// @include fz(20px, normal, #000, 500);
	// margin: 0;
	// font-family: $second_font;
	// text-decoration: none;
	// position: relative;
	// z-index: 10;
	// display: block;
	// padding: 10px 35px 11px;
	// border-radius: 0;
	// letter-spacing: 0.23px;
	// text-align: center;
	// text-transform: uppercase;
	// text-shadow:none;	
	// border: 3px solid #000;
	// background: #edd13b;		
	// top: 0;
	// max-width: 200px;
	// margin-left: auto;
	// margin-right: auto;
	// @include break(custom) {

	// }				
	// @include break(neutral) {
	// 	margin-left: 0;
		
	// }
	// @include break(mobile) {
	// 	margin-top: 20px;
	// }
	// @include break(x-small) {
	// 	display: block;
	// 	width: 100%;
	// 	max-width: none;
	// 	margin-top: 0;
	// }	
	// &:hover, &:active {
	// 	background: #000;
	// 	color: #edd13b;
	// 	border-color: #edd13b;
	// 	box-shadow: none;
	// }
	// &:focus {
	// 	top: 3px;
	// 	color: #edd13b;
	// 	border-color: #edd13b;
	// 	background: #000;
	// 	box-shadow: none;
	// }	
}
.button-wrapper {
	display: inline-block;
	position: relative;
	border-radius: 3px;
	background: #ffbc00;
	transition: all .3s;
	top: 0;
	&:before {
		content: '\f05b';
		font-family: FontAwesome;
		position: absolute;
		font-size: 16px;
		color: #000;
		left: 18px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		transition: all .3s;
	}
	&:hover {
		background: #13205b;
		&:before {
			color: #ffbc00;
		}
	}
	&:active, &:focus {
		top: 3px;
	}
}

.notification_ok {
	padding-bottom: 15px;
	color: #fff;
	line-height: 1.5em;
	font-size: 18px;
	text-align: left;
	padding-top: 15px;
	a {
		color: #fff;
		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}
}

.notification_error {
	color: #e54423;
	clear: both;
	text-align: left!important;
	font-size: 12px;
	line-height: 1.5em;
	font-weight: 700;
}
#note {
	clear: both;
}
#rc-imgelect, .g-recaptcha {
	transform:scale(1);
		transform-origin:0 0;

	@include break(custom){
	}
	@include break(low-medium){
		transform:scale(0.7);
		transform-origin:0 0;
	}
	@include break(neutral){
		transform:scale(1);
		transform-origin:0 0;
	}
	@include break(x-small){
		transform:scale(0.83);
		transform-origin:0 0;
	}

}
.submit_row {
	margin-top: 14px;
	@include break(neutral) {
		margin-top: 5px;
	}
}
img {		height: auto;		max-width:100%;	}
.clearfix {	clear:both;}

.form-wrapper label.error {
	color: #f00;
	font-size: 12px;
	margin-top: -8px;
	display: inline-block;
	font-weight: 400;
	clear:both;
}
.form-wrapper .radio-wrapper label.error {
	order: -1;
	margin-top: 0;
	margin-right: 10px;
}
.form-wrapper .understand-box {
	align-items: center;
	label.error {
		order: 2;
		margin-top: 0;
		&:before {
			display: none;
		}
	}
}
.mobile-none {
	@include break(neutral){
		display: none;
	}
}
.form-wrapper {
	.radio-wrapper {
		display: flex;
		align-items: center;
		input {
			width: auto;
			background: none;
			box-shadow: none;
			margin-right: 10px;
			margin-bottom: 0;
		}
		& + .radio-wrapper {
			margin-left: 20px;
		}
	}
}
.checkbox-flexbox {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: space-between;
	> div {
		width: 48%;
		@include break(x-small) {
			width: 100%;
		}
	}
	input[type="checkbox"] {
		width: 0;
	}
	.checkbox-wrapper {
		display: flex;
	}
	.checkbox-wrapper input[type="checkbox"] {
	    opacity: 0;
	}
	.checkbox-wrapper label {
	    position: relative;
	    display: inline-block;	    
	    padding-left: 22px;
	}

	.checkbox-wrapper label::before,
	.checkbox-wrapper label::after {
	    position: absolute;
	    content: "";	    
	    display: inline-block;
	}

	.checkbox-wrapper label::before{
	    height: 16px;
	    width: 16px;	    
	    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.22);
		border: solid 1px #1d5a9c;
		background-color: rgba(8, 41, 77, 0.1);
	    left: 0px;
	    top: 3px;
	    border-radius: 2px;
	}
	.checkbox-wrapper label::after {
	    height: 5px;
	    width: 9px;
	    border-left: 2px solid;
	    border-bottom: 2px solid;	    
	    transform: rotate(-45deg);	    
	    left: 4px;
	    top: 7px;
	}
	.checkbox-wrapper input[type="checkbox"] + label::after {
	    content: none;
	}
	.checkbox-wrapper input[type="checkbox"]:checked + label::after {
	    content: "";
	}
}
.ccard-info {
	display: none;
	&.boxshow {
		display: block;
	}
}
.form-wrapper {
	.checkbox-flexbox.radio-box {
		> div {
			width: 100%;
		}
		input {
			width: auto;
			margin: 0;
			box-shadow: none;
		}
		label {
			padding-left: 8px;
			&:before, &:after {
				display: none;
			}
		}
	}	
}


/**     
mobile menu   mobile menu    mobile menu   mobile menu      mobile menu        **/



/**     O T H E R       **/
a{color:#38499b; text-decoration:underline;}
a:focus{color:#38499b;text-decoration:none;}
a:hover{text-decoration:none;color:#38499b}
.center {	text-align: center;	}
.scrolloff {  pointer-events: none;}
.map {
	position: relative;
	padding-bottom: 50%;
	padding-top: 30px;
	height: 0;
	background: #2c2c2c;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}
.video {
	position: relative;
	padding-bottom: 50%;
	padding-top: 30px;
	height: 0;
	background: url(../img/img-background.jpg) no-repeat left top / cover;
	box-shadow: 10px 20px 50px -30px rgba(0, 0, 0, 0.5);
	// background: #e3d6cd;
	// box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.5);
	// border: 8px solid #e3d6cd;
	margin-bottom: 20px;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	iframe {
		position: absolute;
		top: 3%;
		left: 2%;
		width: 96%;
		height: 94%;
		// top: 0;
		// left: 0;
		// width: 100%;
		// height: 100%;
		margin: 0;
	}
	@include break(x-small) {
		// border-width: 4px;
		width: 98%;
	}
}
.block-map {
	position: relative;
	height: 438px;
	background: #ccc;
	@include break(low-medium) {
		height: 400px;
	}
	@include break(neutral) {
		height: 350px;
	}
	@include break(mobile) {
		height: 300px;
	}
	@include break(x-small) {
		height: 250px;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}
ul.list, ul.list-empty {
	padding: 0 0 0 5px;
	margin: 0;
	overflow: hidden;
	li {
		padding-left: 31px;
		position: relative;		
		padding-bottom: 10px;
		@include fz(18px, 1.44, #3e1f02, 400);
		font-family: $main_font;
		@include break(neutral) {
		}
		@include break(mobile) {
		}
		@include break(x-small) {
		}	

		&::after {
			content: url(../img/list-icon.svg);
			position: absolute;
			left: 0;
			top: 2px;
			display: block;	  	
			@include break(neutral) {
			}
			@include break(mobile) {
			}
			@include break(x-small) {
			}
		}
	}
}

.paddcontent {
	padding-bottom: 20px;
	@include break(mobile) {
		padding-bottom: 10px;
	}	
}
.bigpadd {
	padding-bottom: 40px;
	@include break(mobile) {
		padding-bottom: 20px;
	}
}
.smallpadd {
	padding-bottom: 10px;
}

/* Buttons */
.btn {
	@include link_trans();
	@include fz(20px, normal, #fff, 600);
	margin: 0;
	font-family: $main_font;
	text-decoration: none;
	position: relative;
	z-index: 10;
	display: inline-block;
	padding: 9px 28px 12px;
	border-radius: 22.5px;
	text-align: center;
	text-shadow:none;	
	border: none;
	box-shadow: none;
	background-color: #55af69;
	background-image: linear-gradient(to right, rgb(85, 175, 105) 0%, rgb(37, 177, 181) 100%);
	top: 0;
	@include break(custom) {

	}				
	@include break(neutral) {
	}
	@include break(mobile) {

	}
	@include break(x-small) {
		display: block;
		width: 100%;
	}
	&:hover, &:active {
		background: #000;
		color: #fff;
		box-shadow: none;
	}
	&:focus {
		top: 3px;
		color: #fff;
		box-shadow: none;
	}
}
.btn-custom {
	@include link_trans();
	@include fz(20px, normal, #000, 500);
	font-family: $second_font;
	text-align: center;
  	border: solid 1px #000;
	padding: 12px 20px 13px;
	display: inline-block;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
	top: 0;
	letter-spacing: 0.23px;
	&:hover, &:active {
		background: #d2d2d2;
		color: #000;
	}
	&:focus {
		top: 3px;
		color: #000;
	}
	@include break(x-small) {
		display: block;
		width: 100%;
	}
}
.btn-back {
	padding-left: 55px;
	&:before {
		content: '\f060';
		font-family: FontAwesome;
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);		
	}
}
.more {
	display: inline-block;
	text-decoration: none;
	padding: 11px 47px 13px 20px;
	border: 1px solid #ccced7;
	border-radius: 22.5px;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	font-family: $main_font;
	color: #adb1c5;
	line-height: normal;
	position: relative;
	top: 0;
	transition: all .3s;
	&:before {
		content: '\f101';
		font-family: FontAwesome;
		color: #cecece;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 22px;
		transition: all .3s;
		font-weight: 400;
	}
	&:hover, &:active {
		color: #fff;
		background-color: #4761e0;
		&:before {
			right: 18px;
		}
	}
	&:focus {
		color: #adb1c5;
		top: 3px;
	}
}
hr {
	margin: 53px 0 29px;
	padding: 0;
	background: #979797;
	height: 1px;
	width: 100%;
	border: 0; 
	@include break(mobile) {
		margin: 20px 0;
	}

}


.red {	color: #ba0003;}
a[href*="tel"]{text-decoration:none; cursor: default;};
::selection{background: #ededed!important;color:#000000};
::-moz-selection{background: #ededed!important;color:#000000};

.cp_reset_style {
	z-index: 1 !important;
}

// MOBILE MMENU

// .mm-navbar, .mm-menu, .mm-panels, .mm-panel {
// 	background: #0b100d;
// 	.sf-menu.mm-listview {
// 		padding-top: 50px;
// 		@include break(smaller) {
// 			max-width: 320px;
// 		}
// 		@include break(very-small) {
// 			max-width: 270px;
// 		}
// 	}
// 	a.mm-listitem__text {
// 		font-size: 18px;
// 		font-family: $main_font;
// 		color: #fff3b9;
// 		line-height: normal;
// 		font-weight: 700;
// 		text-transform: uppercase;
// 		padding: 0 26px 7px;
// 		&:hover, &:active, &:focus {
// 			color: #fccc00;
// 		}
// 	}
// 	.dd_menu  {
// 		a.mm-listitem__text {
// 			font-size: 18px;			
// 		}
// 	}
// 	.mm-listitem {
// 		&:after {
// 			display: none;
// 		}		
// 	}
// 	.mm-navbar {
// 		border: none;
// 	}
// 	.mm-listitem__btn:not(.mm-listitem__text) {
// 		border: none;
// 	}
// 	.mm-btn_next:after {
// 		content: '\f0da';
// 		font-family: FontAwesome;
// 		border: none;
// 		transform: none;
// 		right: auto;
// 		color: #3e1f02;
// 		font-size: 14px;
// 		line-height: 22px;
// 		width: 22px;
// 		height: 22px;
// 		text-align: center;
// 		border-radius: 50%;
// 		background: #fccc00;
// 	}
// 	.mm-btn_prev {
// 		width: 35px;
// 	}
// 	.mm-btn_prev:before {
// 		content: '\f0d9';
// 		border: none;
// 		transform: none;
// 		right: auto;
// 		font-family: FontAwesome;
// 		font-size: 14px;
// 		line-height: 10px;
// 		color: #fccc00;	
// 	}
// 	.mm-navbar__title {
// 		display: block;
// 		text-align: left;
// 		padding-top: 9px;
// 		padding-bottom: 6px;
// 		span {
// 			padding-left: 5px;
// 			font-size: 14px;
// 			font-family: $main_font;
// 			color: #fccc00;
// 			font-weight: 700;
// 			line-height: 58px;
// 			text-transform: uppercase;
// 		}
// 	}
// }
// #mm-0 {
// 	padding-top: 24px;
// 	.mm-navbar {
// 		display: none;		
// 	}
// 	.mm-navbar__title {
// 		span {
// 			font-size: 0;
// 		}
// 	}
// }
.mm-menu {
	background: #1f265d;

	.menu-item-description {
		display: block;
		text-transform: lowercase;
		color: #6e6e6e;
		font-size: 14px;
		font-weight: 400;
	}

	.mm-listview {
		margin-top: 40px;
		padding: 0 24px 0 18px; 
	}
	.mm-highest {
		.mm-listview {
			margin-top: 0;
		}
	}

	.mm-listview > li > a, .mm-listview > li > span {
		position: relative;
		font-size: 16px;
		color: #93ffce;
		line-height: normal;
		font-weight: 600;
		letter-spacing: 0.53px;
		font-family: $main_font;
		padding: 5px 0 12px;
		text-transform: uppercase;
		transition: all 0.3s;
		display: inline-block;
		white-space: normal;
		&:hover, &:active, &:focus {
			color: #fff;
		}
		&.mm-next {
			&:hover, &:active, &:focus {
				border-bottom-color: transparent;
			}
		}
	}

	.mm-listview > li {

		&.menu-item-has-children {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;
		}
	}

	.mm-listview > li:after, .mm-listview .mm-next:before {
		display: none;
	}

	.mm-listview .mm-next + a, .mm-listview .mm-next + span {
		margin-right: 40px;
	}
	.mm-next {
		position: absolute !important;
		top: 0;
		right: 0;
		bottom: 0;
		width: 40px;
	}
	.mm-next:after, .mm-prev:before {
		content: '\f105';
		font-family: FontAwesome;
		color: #fff;
		border: none;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background-color: #51ae6e; 
		line-height: 22px;
		text-align: center;
		font-size: 16px;
		transform: none;
		right: 0;	
		font-weight: 400;
	}
	.mm-prev {
		padding-top: 31px;
	}
	.mm-prev:before {
		content: '\f100';
		height: 15px;
		background: none;
		color: #fff;
		position: relative;
		left: 0;
		text-align: left;
		width: 100px;
	}

	.mm-header {
		border: none;

		.mm-title {
			font-size: 16px;
			color: #fff;
			font-family: $main_font;
			font-weight: 600;
			line-height: normal;
			text-align: left !important;
			padding-top: 32px;
			letter-spacing: 0.53px;
			&:hover, &:active, &:focus {
				color: #fff;
			}
		}
	}
	.dd_menu {
		margin-top: 10px !important;
	}
	.mm-header .mm-btn:first-child {
		padding-left: 14px;
	}
}

#mm-blocker {
	background: rgba(0, 0, 0, .3);
}
.wow {
	visibility: hidden;
}